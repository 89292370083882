import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import numeral from 'numeral';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import { toWords } from 'number-to-words';

 function Downlaod({ quotes, gstno, date, invoice }) {
    const urlParams = new URLSearchParams(window.location.search);
    const quoteIds = urlParams.get('id');
    const arrayValues = urlParams.get('arrayValues');
    const userId = ReactSession.get('userId');
    const Number = ReactSession.get('Number');
    const Address = ReactSession.get('Address');
    const Name = ReactSession.get('userName');
    const Image = ReactSession.get('Image');
    const regid = ReactSession.get("regid");
    const userType = ReactSession.get("userType");
    const Empid = ReactSession.get("empid");let users='';

    const cLogo = ReactSession.get("companyLogo");
    const cName= ReactSession.get("companyName");
    const cGst = ReactSession.get("companyGst");
    const cAddress = ReactSession.get("companyAddress");
    const cContact = ReactSession.get("companyContact");
    const cLocation = ReactSession.get("companyLocation");


    if (userType === '3') {
      users =  Empid ;
    } else {
      users = userId ;
    }
    const user = { "userId": users };
    

    const [quoteData, setQuoteData] = useState(null);
    const [filteredArray, setFilteredArray] = useState([]);
  
    const [bottomFrame, setBottomFrame] = useState('yes');
    const [mesh, setMesh] = useState('no');
    const [panelHeights, setpanelHeight] = useState(0); 
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [screen, setScreen] = useState(deviceWidth > 700 ? 20 : 20);
    const [fanTop, setfanTop] = useState('Top');
    const [fanLeft, setfanLeft] = useState('Left');
    const [divider, setDevider] = useState('Vertical');
    const [switchs, setSwitch] = useState('on');
    const [switchFixed, setFixed] = useState('on');
    const [fanType, setfanType] = useState(1);
    const [total, setTotal] = useState(0);
    const [qty, setQty] = useState(0);
    const [totalframearea, setTotalFramearea] = useState(0);
    const [gst, setGst] = useState(0);
    const [average, setAverage] = useState(0);
    
    
    useEffect(() => {
      const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
        setScreen(window.innerWidth > 700 ? 40 : 40);
      };
  
      window.addEventListener('resize', updateDeviceWidth);
  
      return () => {
        window.removeEventListener('resize', updateDeviceWidth);
      };
    }, []);

  
    const fetchData = async () => {
      try {
        const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, user);
        setQuoteData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    // Ensure quoteData is valid before using filter method
    const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
    const profileImage = filteredQuotes &&  filteredQuotes[0]?.quoteDetails[0]?.quoteData?.selectedProfile || 'profile_logo';
    console.log(filteredQuotes);


    useEffect(() => {
      if (filteredQuotes && filteredQuotes.length > 0) {
        let sum = 0;
        let qty = 0;
        let totalframe = 0;
        let average = 0;
        filteredQuotes.forEach(quote => {
          quote.quoteDetails.forEach(detail => {
            qty +=(detail.quoteData.qty);
            totalframe +=(detail.quoteData.frameArea*detail.quoteData.qty);
            sum += (detail.quoteData.grandTotal*detail.quoteData.qty);
            
           // console.log(sum);
          });
        });
        average=(sum/totalframe);
        //console.log(average);
        setTotal(sum);
        setQty(qty);
        setTotalFramearea(totalframe);
        setGst((18/100)*sum);
        setAverage(average);
      }
    }, [filteredQuotes]);
    
  
    useEffect(() => {
      if (arrayValues) {
        // Split arrayValues string into an array of values
        const valuesArray = arrayValues.split(',');
  
        // Filter quotes based on valuesArray
        const filtered = quotes.filter(quote =>
          valuesArray.every(value => quote.details.includes(value))
        );
  
        setFilteredArray(filtered);
        //console.log(filtered);
      }
    }, [arrayValues, quotes]);
  
    
    
  
    
  
    return (
  
      <div >

        <div class="modal fade custom-modal" id="onloadModals1" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content" align="center">
               <div class="mt-3 mb-3">
                <h5>Preparing Invoice...</h5>
               </div>
                
            </div>
          </div>
        </div>


     
      <div className='hide' id="pdf-content-invoice" style={{minWidth: '1000px', maxWidth: '1000px', padding:"20px"}}>        
	    <table width='100%'  id="first-table-1">
        
      <th width='40%' style={{textAlign: 'left', padding: '20px', verticalAlign: 'middle'}}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
   
  <div style={{ display: 'flex', flexDirection: 'column' }}>
  <img src={`assets/user/${cLogo}`} width='30%' alt={cLogo} style={{ marginRight: '10px', marginBottom: '5px' }} />
    <p style={{ fontSize: '15px', margin: 0 , color: '#36454F'}}> {cName} </p>
        <p style={{ fontSize: '12px', margin: 0 , color: '#36454F'}}> 

        {cAddress} <br />
        {cContact}<br />
        GST : {cGst}
      </p>
    </div>
  </div>
</th>

		 {/* <th width='10%'  style={{textAlign: 'center',}}>
		
        <p> {Name} <br />
		     {Address}<br />
		     {Number}
        </p>
		 </th> */}
     <th width='30%' style={{ textAlign: 'right', verticalAlign: 'middle', padding: '20px', verticalAlign: 'middle'}}>
     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
    {/* <img 
      src="assets/images/logo.png" 
      width='35%' 
      alt='Logo' 
      style={{ marginBottom: '10px' }} 
    /> */}
    
            {/* <h6 style={{color: '#36454F', marginRight:'32px'}}>MEMBER</h6>
            <img 
            style={{color: '#36454F', marginRight:'25px'}}
              src="assets/images/favicon.svg"
              width='20%' 
              alt='Logo' 
            /> */}
            
            <h6 style={{color: '#36454F' , marginRight:'30px'}}>DATE: {date}</h6>
            <h6 style={{color: '#36454F', marginRight:'30px'}}>Invoice No : {invoice}</h6>
            <img 
              src={`assets/images/${profileImage}.jpg`}
              width='30%' 
              alt='Logo' 
              style={{ marginRight:'12px'}}
            />

    {/* {filteredQuotes?.map((quote, index) => (
      <React.Fragment key={index}>
            <img 
              src={quote.quoteDetails[0].quoteData.selectedProfile === 'Window Tech' ? "assets/images/logo1.jpg" : "assets/images/logo2.jpg"} 
              width='30%' 
              alt='Logo' 
              style={{ marginRight:'12px'}}
            />
      </React.Fragment>
    ))} */}
    
  </div>
</th>


	    </table>
      <hr></hr>
      <table width='100%' id="first-table-2">
      <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
    <th  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',  color:'#333' }}>
    Shipping Address
  </th>
  <th style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',  color:'#333' }}>
  Billing Address
  </th>

  
  
    </tr>	
		 <th width='50%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',  color: '#36454F'} }>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
       <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.name}</h5>
      <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.location}</h5>
      <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.number}</h5>
      
    </div>
  ))}
     </th>
		 <th width='50%'   style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',  color: '#36454F'} }>
     {filteredQuotes?.map((quote, index) => (
    <div key={index}>
      <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.name}</h5>
      <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.location}</h5>
      <h5 style={{color: '#36454F',fontSize:'15px'}}>{filteredQuotes[0].client.number}</h5>
      
      
    </div>
  ))}
		 </th>
	    </table>
     
     
	    <table width='100%' style={{width:"100%", marginTop:'30px', border:'0.5px solid #666',  borderCollapse: 'collapse'}} id="second-table" >
       
       
            <thead >
                <tr>
                   <th style={{border:'0.5px solid #666',  padding:'2px', width:"5%", textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>S.No</th>
                   <th width='20%' style={{border:'0.5px solid #666',  padding:'2px',  textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Description</th>
                   <th style={{border:'0.5px solid #666',  padding:'2px', width:"15%", textAlign:'center', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>HSN Code</th>
                  <th style={{border:'0.5px solid #666', padding:'2px', width:"15%",textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Rate/sqft</th>
                    <th style={{border:'0.5px solid #666', padding:'2px',width:"15%", textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Total Area</th>
                    <th style={{border:'0.5px solid #666', padding:'2px',width:"15%", textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>Amount</th>
                </tr>
            </thead>
            {filteredQuotes?.map((quote, index) => (
            <tbody key={index} >
              {quote.quoteDetails.map((detail, detailIndex) => (
                <tr key={detailIndex} >
                    <td  style={{border:'0.5px solid #666', padding:'10px', alignContent: 'center', alignItems: 'center', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detailIndex+1}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{detail.quoteData.item}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>39252000</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif',color:"#36454F"}}>₹ {numeral(detail.quoteData.grandTotal/detail.quoteData.frameArea).format('0,0.00')}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{numeral(detail.quoteData.frameArea*detail.quoteData.qty).format('0.00')}</td>
                    <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'right',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹ {numeral(detail.quoteData.grandTotal*detail.quoteData.qty).format('0,0.00')}</td>
                </tr>
                
              ))}
             

               
              
                {/* Add more rows as needed */}
            </tbody>
          ))}
          
          <tfoot>
			<tr >
        
				<td colspan="3"></td>
      
        <th style={{border:'0.5px solid #666', textAlign:"center", padding:'2px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Total</th>
        <td style={{border:'0.5px solid #666', padding:'2px', textAlign:'center',fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>{numeral(totalframearea).format('0.00')}</td>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px',fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(total).format('0,0.00')}</td>
			</tr>
      
      <tr >
      <td colspan="4" ></td>
				<th  style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>GST 18%</th>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', paddingLeft:'20px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(gst).format('0,0.00')}</td>
			</tr>
      <tr>
      <td colspan="4" ></td>
				<th  style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>Grand Total</th>
				<td  style={{border:'0.5px solid #666', textAlign:"right", padding:'10px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>₹{numeral(total+gst).format('0,0.00')}</td>
			</tr>


      <tr>
      
			<td colspan="6" style={{textAlign:"left", padding:'10px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>

        <p style={{color:"#36454F"}}>Amount Chargable in Words</p>
        <h5 style={{color:"#36454F"}}>INR {toWords(total+gst).toUpperCase()} ONLY</h5>
        </td>
       
			</tr>
      <tr>
    
      <td colspan="4" style={{textAlign:"left", padding:'10px',fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>

        <h3 style={{color:"#36454F"}}>Declaration:</h3>
        <h5 style={{color:"#36454F"}}> 
          We declare that this invoice shows the actual price of the 
          goods described and that all particulars are true and 
          correct.
        </h5>
      </td>
      <td colspan="2" style={{border:'0.5px solid #666', textAlign:"left", padding:'10px', paddingLeft:'50px', fontSize:'15px', fontFeatureSettings:'salt', fontFamily:'--bs-font-sans-serif', color:"#36454F"}}>
        for {cName}
        <p style={{padding:'30px'}}> Authorised Signatory</p>
      </td>
				
			</tr>
		</tfoot>



    
           
        </table>
      </div>
     
    </div>
   
  )
}
export default Downlaod;
